<template>
    <el-main>
        <el-button type="primary" size="small" class="add_goods" @click="$router.push(`/extension/couponAdd`)">
            + 添加优惠券
        </el-button>
        <el-form class="el-form-search" label-width="110px">
            <el-form-item label="优惠券名称：">
                <el-input size="small" v-model="name" placeholder="请输入优惠券名称"></el-input>
            </el-form-item>
            <el-form-item label="优惠券状态：">
                <el-select size="small" v-model="status" placeholder="请选择">
                    <el-option v-for="item in opStatus" :key="item.value" :label="item.label"
                        :value="item.value"></el-option>
                </el-select>
            </el-form-item>
            <el-form-item label="优惠券类型：">
                <el-select size="small" v-model="type" placeholder="请选择">
                    <el-option v-for="item in opType" :key="item.value" :label="item.label" :value="item.value"></el-option>
                </el-select>
            </el-form-item>
            <el-form-item label=" " label-width="21px">
                <el-button size="small" type="primary" @click="getCouponList()">搜索</el-button>
                <el-button size="small" type="text" @click="clearSearch">清空搜索条件</el-button>
            </el-form-item>
        </el-form>
        <el-table :data="list" :header-cell-style="{ 'background-color': '#f8f9fa' }">
            <template slot="empty">
                <No />
            </template>
            <el-table-column prop="name" label="优惠券名称" width="220" align="center"></el-table-column>
            <el-table-column prop="type" label="优惠券类型" align="center">
                <template slot-scope="scope">
                    <span>{{ scope.row.type == 1 ? '满减券' : scope.row.type == 2 ? '立减券' : '满折券' }}</span>
                </template>
            </el-table-column>
            <el-table-column prop="rule" label="优惠规则" align="center"></el-table-column>
            <el-table-column prop="title" label="用户资格" align="center"></el-table-column>
            <el-table-column prop="each_limit" label="每人限领" align="center"></el-table-column>
            <el-table-column prop="get_number" label="领取量" align="center"></el-table-column>
            <el-table-column prop="use_number" label="使用数量" align="center" width="120"></el-table-column>
            <el-table-column prop="number" label="发放数量"></el-table-column>
            <el-table-column prop="activity_status" label="活动状态" align="center" width="120">
                <template slot-scope="scope">
                    <span v-if="scope.row.status">{{ scope.row.activity_status == 0 ? '未开始' : scope.row.activity_status == 1
                        ? '进行中' : '已结束' }}</span>
                    <span v-else>已停止</span>
                </template>
            </el-table-column>
            <el-table-column prop="end_time" label="有效期" align="center">
                <template slot-scope="scope">
                    <span>{{ getDateformat(scope.row.activity_end_time) }}</span>
                </template>
            </el-table-column>
            <el-table-column label="操作" width="200" align="center">
                <template slot-scope="scope">
                    <el-button @click="edit(scope.row)" type="text" size="small">编辑</el-button>
                    <el-button @click="remove(scope.row.id)" type="text" size="small">删除</el-button>
                    <el-button v-show="scope.row.activity_status == 1 && scope.row.status"
                        @click="end(scope.row, scope.$index)" type="text" size="small">停止发放</el-button>
                    <el-button
                        v-show="(scope.row.activity_status == 0 || scope.row.activity_status == 1) && scope.row.status == 0"
                        @click="recovery(scope.row)" type="text" size="small">恢复</el-button>
                </template>
            </el-table-column>
        </el-table>
        <Paging class="paging" :total="total" :page="page" :pageNum="pageNum" @updatePageNum="updateData"></Paging>
    </el-main>
</template>

<script>
import Paging from '../../components/paging.vue';
import { getDateformat } from '../../util/getDate';
export default {
    components: {
        Paging,
    },
    data () {
        return {
            name: '',
            page: 1,
            pageNum: 10,
            total: 0,
            list: [],
            status: '3',
            type: '4',
            opStatus: [
                {
                    value: '3',
                    label: '全部',
                },
                {
                    value: '1',
                    label: '停用',
                },
                {
                    value: '2',
                    label: '正常',
                },
            ],
            opType: [
                {
                    value: '4',
                    label: '全部',
                },
                {
                    value: '1',
                    label: '满减券',
                },
                {
                    value: '2',
                    label: '立减券',
                },
                {
                    value: '3',
                    label: '满折券',
                },
            ],
        };
    },
    created () {
        this.getCouponList();
    },
    methods: {
        clearSearch () {
            this.name = '';
            this.status = '3';
            this.type = '4';
            this.getCouponList();
        },
        updateData (val, status) {
            if (status == 0) {
                this.pageNum = val;
                this.getCouponList();
            } else {
                this.page = val;
                this.getCouponList();
            }
        },
        getDateformat (val) {
            return getDateformat(val);
        },
        end (row, index) {
            this.$confirm('确认是否停止发放？', '提示', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning',
            }).then(() => {
                this.$axios
                    .post(this.$api.push.editStatus, {
                        id: row.id,
                        status: 0,
                    })
                    .then((res) => {
                        if (res.code == 0) {
                            this.list[index].status = 0;
                            this.$message({
                                message: '停止成功',
                                type: 'success',
                            });
                        } else {
                            this.$message.error(res.msg);
                        }
                    });
            });
        },
        recovery (row) {
            this.$confirm('确认是否恢复此优惠券？', '提示', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning',
            }).then(() => {
                this.$axios
                    .post(this.$api.push.editStatus, {
                        id: row.id,
                        status: 1,
                    })
                    .then((res) => {
                        if (res.code == 0) {
                            this.getCouponList();
                            this.$message({
                                message: '恢复成功',
                                type: 'success',
                            });
                        } else {
                            this.$message.error(res.msg);
                        }
                    });
            });
        },
        edit (row) {
            this.$router.push({
                path: '/extension/couponEdit',
                query: {
                    info: JSON.stringify(row),
                },
            });
        },
        remove (id) {
            this.$confirm('确认删除此优惠券？', '提示', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning',
            }).then(() => {
                this.$axios
                    .post(this.$api.push.couponEel, {
                        id: id,
                    })
                    .then((res) => {
                        if (res.code == 0) {
                            this.$message({
                                message: '删除成功',
                                type: 'success',
                            });
                            this.getCouponList()
                        } else {
                            this.$message.error(res.msg);
                        }
                    });
            });
        },
        getCouponList () {
            let data = {
                page: this.page,
                rows: this.pageNum,
                name: this.name,
            };
            if (this.status != 3) data.status = this.status;
            if (this.type != 4) data.type = this.type;
            this.$axios.post(this.$api.push.couponList, data).then((res) => {
                if (res.code == 0) {
                    let list = res.result.list;
                    this.total = res.result.total_number;
                    this.list = list;
                } else {
                    this.$message.error(res.msg);
                }
            });
        },
    },
};
</script>

<style lang="less" scoped>
.el-main {
    background: #fff;
}</style>
